<template>
  <v-chip
    small
    label
    :close="close"
    target="_blank"
    :color="color"
    :href="file.fileurl"
    class="mr-2 alignchip"
    @click:close="handleClose()"
  >
    <v-icon small left> fas fa-paperclip </v-icon>
    {{ file.filename }}
  </v-chip>
</template>

<script>
export default {
  name: "Attachment",
  props: {
    file: {
      default: () => {},
      type: Object,
    },
    color: {
      default: () => "",
      type: String,
    },
    close: {
      default: () => false,
      type: Boolean,
    },
  },
  created() {
    console.log("inside attachment", this.close)
  },
  methods: {
    //emits event to parent componenent to remove attachment
    handleClose() {
      this.$emit("click:close")
    },
  },
}
</script>

<style lang="scss" scoped>
.alignchip {
  border-radius: 0 !important;
}
</style>
