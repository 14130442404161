
<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <Tabs :tabs="allTabStatus" @settabvalue="option = $event" />
    <v-data-table
      :headers="headers"
      :items="getservicearray"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="ID"
      :show-expand="expandflag"
      class="elevation-1"
      :items-per-page="roles.itemsPerPage"
      :loading="notloaded"
      loading-text="Loading..."
      :search="search"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:[`item.images`]="{ item }">
        <span v-for="(image, index) in item.images" :key="index">
          <img :src="image.fileurl" alt="image.filename" width="40px" height="20px" />
        </span>
      </template>

      <template v-slot:[`item.modifiedOn`]="{ item }">
        {{ convertDate(item.modifiedOn) }}
      </template>

      <template v-slot:[`item.createdOn`]="{ item }">
        {{ convertDate(item.createdOn) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Service Requests</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on" @click="getSRNumber">NEW SR</v-btn>
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false" />
                    <v-row>
                      <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.descriptions"
                          label="Descriptions"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="2" lg="2">
                        <v-select
                          v-model="editedItem.severity"
                          label="Severity"
                          :rules="inputrequired"
                          :items="allSeverity"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-select v-model="editedItem.status" :items="allStatus" :rules="inputrequired" label="Status">
                        </v-select>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                        <v-combobox
                          v-model="editedItem.assignedTo"
                          :items="allnames"
                          :rules="inputrequired"
                          label="Assigned To"
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                        <div><h4>Comments</h4></div>
                        <tipTapVuetify
                          :tiptablable="'Comments'"
                          :tiptabstring="editedItem.comments"
                          @assigntiptabvalues="editedItem.comments = $event"
                        />
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                        <Upload
                          ref="Attachment"
                          :attachments="editedItem.images"
                          :storagepath="storagePath"
                          :uploadname="`Attachment`"
                          :disabled="disabled"
                          @addAttachment="addAttachment"
                          @removeAttachment="removeAttachment"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" :disabled="!valid" text @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
              <h4>Changed By</h4>
              {{ item.modifiedBy }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Changed On</h4>
              {{ convertDate(item.modifiedOn) }}
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="4" lg="4">
              <h4>Assigned To</h4>
              {{ item.assignedTo }}
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="6" lg="6">
              <div><h4>Comments</h4></div>
              <div v-html="item.comments"></div>
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="6" lg="6">
              <h4>Attachment</h4>
              <Attachment
                v-for="(file, index) in item.images"
                :key="file.filename"
                :file="file"
                :index="index"
                :close="false"
                :color="'green'"
              />
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <!--<v-icon  small @click="deleteItem(item)" > mdi-delete </v-icon>-->
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add SR</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {  usersRef, ITRef, domainsRef } from "@/fb"
import { formMixin } from "@/mixins/formMixin.js"
import { emailMixin } from "@/mixins/emailMixin.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"
import Tabs from "@/components/common/Tabs.vue"
import tipTapVuetify from "@/components/common/tipTapVuetify.vue"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import { convertAll } from "@/mixins/convertall.js"
import Upload from "@/components/common/Upload.vue"
import Attachment from "@/components/common/Attachment.vue"
import {mapState} from "vuex"
export default {
  name: "ITService",
  //props:['roles'],
  mixins: [formMixin, emailMixin, collectionsOperation, convertAll],
  components: { Snackbar, Alertmsg, Spinner, Tabs, tipTapVuetify, Attachment, Upload },
  data: () => ({
    date: null,
    menuDOS: false,
    dialog: false,
    menupd: false,
    menuied: false,
    search: "",
    domain: "",
    notloaded: true,
    singleExpand: false,
    expanded: [],
    expandflag: true,
    lazy: false,
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    sysadmin: false,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "ID",
      },
      { text: "Descriptions", value: "descriptions" },
      { text: "Severity ", value: "severity" },
      { text: "Status", value: "status" },
      { text: "Created By", value: "createdBy" },
      { text: "Created On", value: "createdOn" },
      { text: "modifiedOn", value: "modifiedOn", align: " d-none" },
      { text: "modifiedBy", value: "modifiedBy", align: " d-none" },
      { text: "assignedTo", value: "assignedTo", align: " d-none" },
      { text: "images", value: "images", align: " d-none" },
      { text: "comments", value: "comments", align: " d-none" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    servicerequest: [],
    showservicerequest: [],
    editedIndex: -1,
    SRNumber: 0,
    name: "",
    allnames: [],
    sysadminemail: null,

    editedItem: {
      ID: null,
      createdBy: null,
      createdOn: null,
      status: null,
      modifiedOn: null,
      modifiedBy: null,
      severity: null,
      descriptions: null,
      assignedTo: null,
      images: [],
      comments: null,
      domain: null,
      createdEmail: null,
    }, // end of editedItem
    defaultItem: {},
    
    allTabStatus: ["ALL", "New", "Open", "WIP", "Pending", "Closed"],
    allStatus: ["New", "Open", "WIP", "Pending", "Closed"],
    allSeverity: ["Crtical", "Major", "Medium", "Low"],
    editflag: false,
   
    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },

    inputrequired: [(v) => !!v || "Input is required"],

    snackbar: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    updateSR: false,
    newSR: false,
    roles: "",
    option: "ALL",
    spinner: false,
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add SR" : "Edit SR"
    },
    getservicearray() {
      return this.option == "ALL" ? this.servicerequest : this.servicerequest.filter((sr) => sr.status == this.option)
    },
    storagePath() {
      var testrportstoreagepath = "ITService/" + this.domain + "_" + this.editedItem.ID
      return testrportstoreagepath
    },
     ...mapState({
      currentDomain: state=> state.userProfile.domain
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile
    console.log("servicerequest crated", this.roles)
    if (this.roles) {
      if (this.roles.role.sysadmin) {
        this.sysadmin = true
      }
      this.domain = this.roles.domain
      this.name = this.roles.name
      this.editflag = true;
      this.defaultItem=Object.assign({}, this.editedItem)
      this.initialize()
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },

   mounted() {
    ITRef.where("domain", "==", this.currentDomain).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
          if(!this.notloaded)
          this.updaterealtimedata(this.servicerequest, change)
      }) // end of for each
    }) // end of snapshot
  }, // end of mounted

  methods: {
  
    addAttachment(options, fileDetails) {
      console.log("addAttachment options", options)
      this.editedItem.images.push({ ...fileDetails })
    },
    //  it will remove the attachment values in the repective array.
    removeAttachment(options, fileDetails) {
      console.log("removeAttachment options", options,fileDetails,this.editedItem.images)

      let files = this.editedItem.images.filter((v) => v.filename != fileDetails.filename)
      this.editedItem.images = [...files]
    },

    async getSRNumber() {
      this.SRNumber = 0
      var queryresult = await domainsRef.where("domain", "==", this.domain).get()
      this.SRNumber = queryresult.docs[0].data().srnumber
      this.sysadminemail = queryresult.docs[0].data().sysadminemail
      var uid = queryresult.docs[0].id
      this.SRNumber++
      domainsRef.doc(uid).update({
        srnumber: this.SRNumber,
      })
    },

    quick_Sort(origArray) {
      if (origArray.length <= 1) {
        return origArray
      } else {
        var left = []
        var right = []
        var newArray = []
        var pivot = origArray.pop()
        var length = origArray.length

        for (var i = 0; i < length; i++) {
          if (origArray[i].createdOn >= pivot.createdOn) {
            left.push(origArray[i])
          } else {
            right.push(origArray[i])
          }
        }
        return newArray.concat(this.quick_Sort(left), pivot, this.quick_Sort(right))
      }
    },

    async initialize() {
      this.servicerequest = [];
      var ret = await this.loadInitialData(ITRef, this.domain, this.servicerequest)
      if (ret != true) {
        this.alertflag = true
        this.alertmsg = ret
        this.notloaded = false
      } else if (!this.sysadmin) {
        this.servicerequest = this.servicerequest.filter((res) => res.createdEmail == this.roles.email)
      }
   
      this.quick_Sort(this.servicerequest)     
      await usersRef
        .where("domain", "==", this.domain)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().role.includes("sysadmin") )
            this.allnames.push(doc.data().email)
          })
        })
        .catch((err) => console.log(err.message))
      this.notloaded = false
    },

    editItem(item) {
      this.editedIndex = this.servicerequest.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.editedItem = JSON.parse(JSON.stringify(item))
      this.alertflag = false
      this.dialog = true
    },
    async deleteItem(item) {
      const deletemsg = "Are you sure you want to delete this profile?" + item.ID
      var Ret = await this.commonDeleteItem(item, this.servicerequest, ITRef, deletemsg)
      console.log("Return from delete Item", Ret)
      if (Ret == true) {
        this.snackbar = true
        this.snackbartext = "The asset has been deleted successfully"
      } else {
        this.alertflag = true
        this.alertmsg = "Error while delete" + Ret
      }
    },

    close() {
      this.dialog = false
      this.spinner = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.editedIndex > -1) {
        Object.assign(this.servicerequest[this.editedIndex], this.editedItem)

        this.editedItem.domain = this.domain // add this as domain name from login page.
        this.editedItem.modifiedOn = new Date().toISOString().substr(0, 10)
        this.editedItem.modifiedBy = this.roles.name
        console.log("images", this.editedItem.images)
        if (this.editedItem.images == null) {
          console.log("images is null")
          this.editedItem.images = []
        }
        if (this.editedItem.comments == null)
          (this.editedItem.comments = JSON.parse(JSON.stringify(this.editedItem.comments))),
            console.log("inside update item ", this.editedItem)

        try {
          await ITRef.doc(this.editedItem.uid).set({
            ...this.editedItem
          })
          console.log("after updated", this.editedItem.status)

          this.snackbar = true
          this.snackbartext = "Service request updated successfully"
          this.updateSR = true
         
          this.mailfun()
          this.close()
        } catch (error) {
          console.log("Error while update :", error)
          this.alertflag = true
          this.spinner = false
          ;(this.alertmsg = "Error while update the profile"), +error
        }
      } // end of if
      else {
        this.editedItem.domain = this.domain // add this as domain name from login page.
        this.editedItem.createdOn = new Date().toISOString().substr(0, 10)
        this.editedItem.createdEmail = this.roles.email
        this.editedItem.createdBy = this.roles.name
        this.editedItem.ID = this.SRNumber
        this.editedItem.comments=JSON.parse(JSON.stringify(this.editedItem.comments)),
        console.log("inside new item add", this.editedItem)

        try {
          await ITRef.add({
          ...this.editedItem
          })
            .then((docRef) => {
              this.editedItem.uid = docRef.id
               var objIndex = this.servicerequest.findIndex((obj) => obj.uid == this.editedItem.uid)
            if (objIndex < 0) this.servicerequest.push(this.editedItem)
            })
            .catch((error) => console.error("Error adding ITRequest: ", error))
          this.snackbar = true
          this.snackbartext = "Service request added successfully"

          if (this.editedItem.assignedTo) {
            this.newSR = true
            this.mailfun()
          }
          this.close()
        } catch (error) {
          this.alertflag = true
          this.spinner = false
          ;(this.alertmsg = "Error while add the profile"), +error
          console.log("profile add error", error)
        }
      } // end of this.editedItem.images value found
    },
    mailfun() {
      if (this.newSR) {
        this.mailmsg.to = this.editedItem.assignedTo
        this.mailmsg.subject = "NEW SR : " + this.editedItem.ID
        this.mailmsg.message =
          "Hi, New SR assigned in your queue " + this.editedItem.ID + ". Please login to portal and check the details."
        // this.mailmsg.message= this.mailmsg.message+'<a href="' + cvarray + '">Clik here to download the CV</a>'
      } else if (this.updateSR) {
        this.mailmsg.to = this.editedItem.assignedTo + ", " + this.editedItem.createdEmail
        this.mailmsg.subject = " SR Update : " + this.editedItem.ID
        this.mailmsg.message =
          "Hi,  SR has been updated  " + this.editedItem.ID + ". Please login to portal and check the details."
      }
      this.newSR = false
      this.updateSR = false

      console.log(this.mailmsg)
      this.sendEmail(this.mailmsg)
    },
  }, // end of method
}
</script>
